<template>
  <div id="abc">
  <v-app id="inspire">
    <div class="text-center">
      <v-btn
        color="blue"
        dark
        @click="sheet = !sheet"
      >
        Open v-model
      </v-btn>
      <v-bottom-sheet v-model="sheet" inset>
        <v-sheet
          class="text-center"
          height="200px"
        >
          <v-btn
            class="mt-6"
            text
            color="red"
            @click="sheet = !sheet"
          >
            close
          </v-btn>
          <div class="py-3">
            This is a bottom sheet using the controlled by v-model instead of activator
          </div>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-app>
</div>
</template>

<script>
  export default {
    data: () => ({
      sheet: false,
      tiles: [
        { img: 'keep.png', title: 'Keep' },
        { img: 'inbox.png', title: 'Inbox' },
        { img: 'hangouts.png', title: 'Hangouts' },
        { img: 'messenger.png', title: 'Messenger' },
        { img: 'google.png', title: 'Google+' },
      ],
    }),
  }
</script>

<style scoped>
.v-bottom-sheet.v-dialog {
    margin-left: 260px !important;
}
</style>